
import { Select, Option } from "element-ui";
import {Menu as MenuApi, Location, Concept, Employee, Connect, Category} from "@/services/SOLO";
import {
  Category as CategoryModel,
  Location as LocationModel,
  Application as ApplicationModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler, translations } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";

interface objKey {
  [x: string]: string | number;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getLocale: 'app/getLocale',
      userRole: 'account/getUserRole',
    }),
  },
  mixins: [eventHandler, translations],
})
export default class ItemUpdate extends Vue {
  translate!: any
  items: Array<any> = [];
  selectedLocations: any = [];
  selectedLocationsPrice: any = [];
  testPrice: any = [];
  disabledLocations: any = [];
  notBelongLocations: any = [];
  selectedApplications: any = [];
  selectedOutOfStockLocations: any = [];
  selectedConnections: any = [];
  defaultLocations: Array<LocationModel> = [];
  defaultApplications: Array<ApplicationModel> = [];
  locations: Array<LocationModel> = [];
  applications: Array<any> = [];
  connections: any[] = [];
  arrayLang: Array<string> = [];
  itemNames: objKey = {};
  itemDescs: objKey = {};
  imageType: number = 0;
  isShow: Boolean = true;
  $notify: any;
  numOfLoadedData: number = 0;
  file: any;
  isSpecialPricing: Boolean = true;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ];
  getLanguages!: Function
  waiting!: Function;
  restore!: Function;
  getLang!: Function;
  getLocale!: any;
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
  getUser!: any;
  userRole!: any;
  employeeLocations: any = [];
  categories: Array<CategoryModel> = [];
  categoryId: any;
  selectedImage: any;
  page: number = 1;
  durationType: string = 'None';
  durations: any = [];
  numOfHours: any = null;
  // total: number = 0;
  // currentPage: number = 1;
  // perPage: number = 0;
  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {

  }
  @Watch("numOfLoadedData", { immediate: true, deep: true })
  onLoad(data: number) {
    console.log(data, this.arrayLang.length)
    if(this.arrayLang.length) {
      if (data === this.arrayLang.length) {
        console.log("sssssssssa", this.items[0])
        this.selectedLocationsPrice = this.items[0].attributes?.prices;
        this.isSpecialPricing = !!this.items[0].attributes?.prices?.length;
        if(this.items[0]?.relationships?.apps?.data?.length) {
          this.items[0].relationships.apps.data.map((data: any) => {
            this.selectedApplications.push(data.id);
          });
        }
        
        if(this.items[0]?.relationships?.['excluded-connections']?.data?.length) {
          this.items[0].relationships['excluded-connections'].data.forEach((data: any) => {
            this.selectedConnections.push(data.id);
          });
        }
        if (this.items[0]?.relationships?.locations?.data?.length) {
          const locationsData = this.items[0]?.relationships?.locations?.data;
          const mapData = (data: any) => data.id;
          if (this.isMenuRestaurant) {
            this.getEmployeeLocations()
              .then(() => {
                this.selectedLocations = locationsData
                  .filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id))
                  .map(mapData);

                this.disabledLocations = [...this.selectedLocations];
                this.notBelongLocations = locationsData
                  .map(mapData)
                  .filter((lo: any) => !this.selectedLocations.includes(lo));
              });
          } else {
            this.selectedLocations = locationsData.map(mapData);
            this.disabledLocations = locationsData.map(mapData);
          }
        }

        if (this.items[0]?.relationships?.['out-of-stock-locations']?.data?.length) {
          const locationsData = this.items[0]?.relationships?.['out-of-stock-locations']?.data;
          const mapData = (data: any) => data.id;
          if (this.isMenuRestaurant) {
            this.getEmployeeLocations()
              .then(() => {
                this.selectedOutOfStockLocations = locationsData
                  .filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id))
                  .map(mapData);

                this.disabledLocations = [...this.selectedOutOfStockLocations];
                this.notBelongLocations = locationsData
                  .map(mapData)
                  .filter((lo: any) => !this.selectedOutOfStockLocations.includes(lo));
              });
          } else {
            this.selectedOutOfStockLocations = locationsData.map(mapData);
            this.disabledLocations = locationsData.map(mapData);
          }
        }

        // if(this.items[0].relationships.locations.data.length) {
        //   this.items[0].relationships.locations.data.map((data: any) => {
        //     this.selectedLocations.push(data.id);
        //     this.disabledLocations.push(data.id);
        //   });
        // }
      }
    }
  }
  @Watch("categories", { immediate: true, deep: true })
  onCategoriesChange(val: any) {
    if (val) {
      this.selectedImage = val.find((item: any) => parseInt(item.id) == this.categoryId)?.attributes['image-uri'];
    }
  }
  created() {
    this.arrayLang = this.getLanguages()
  }
  mounted() {
    this.getApps();
    this.getConnections();
    this.getLocations(1);
    this.initializeForms();
    this.getCategory();

    this.categoryId = this.$route.params.catId;

    this.durations = [
      {
        value: "none",
        text: "None"
      },
      {
        value: "1",
        text: "1 Hour"
      },
      {
        value: "4",
        text: "4 Hours"
      },
      {
        value: "__next_business_day__",
        text: "Next Business Date"
      },
      {
        value: "custom",
        text: "Custom"
      }
    ];
  }

  get menuId(): string {
    return this.$route.params.id;
  }

  getCategory() {
    Category.all(this.menuId, this.page).then((response: any) => {
      this.categories = response.data.data;

      // this.total = response.data.meta.pagination.total;
      // this.currentPage = response.data.meta.pagination.current_page;
      // this.perPage = response.data.meta.pagination.per_page;
    }).catch((err: any) => {
      console.log(err.response)
    });
  }

  updateCategory(data: any) {
    this.selectedImage = this.categories.find((item: any) => parseInt(item.id) == data)?.attributes['image-uri'];
  }

  get isEnabled(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return this.items[0].attributes.enabled ? true : false;
    else return false;
  }
  set isEnabled(newVal: boolean) {
    this.items[0].attributes.enabled = newVal === true ? 1 : 0;
  }

  // disable-for-delivery
  get isEnabledDelivery(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return this.items[0].attributes['disable-for-delivery'] ? true : false;
    else return false;
  }
  set isEnabledDelivery(newVal: boolean) {
    this.items[0].attributes['disable-for-delivery'] = newVal === true ? 1 : 0;
  }

  // disable-for-pickup
  get isEnabledPickup(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return this.items[0].attributes['disable-for-pickup'] ? true : false;
    else return false;
  }
  set isEnabledPickup(newVal: boolean) {
    this.items[0].attributes['disable-for-pickup'] = newVal === true ? 1 : 0;
  }


  // disable-for-allow-discount
  get isEnabledAllowDiscount(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return this.items[0].attributes['is-discount-allowed'] ? true : false;
    else return false;
  }
  set isEnabledAllowDiscount(newVal: boolean) {
    this.items[0].attributes['is-discount-allowed'] = newVal === true ? 1 : 0;
  }
  onAddLocationPrice() {
    this.isShow = true;
    this.selectedLocationsPrice.push({
      location: null,
      price: null,
    });
  }
  onDeleteLocationPrice(index: number) {
    this.selectedLocationsPrice.splice(index, 1);
  }

  initializeForms() {
    for (let i in this.arrayLang) this.findItems(this.arrayLang[i]);
  }
  findItems(lang: string = "en-us") {
    MenuApi.items(
      this.$route.params.id,
      this.$route.params.catId,
      this.$route.params.itemId,
      lang
    ).then((response: any) => {
      let data = { ...response.data.data, ...{ lang: lang } }
      this.items.push(data)
      this.items.sort((a, b) => {
        return b.lang.localeCompare(a.lang)
      })
      this.numOfLoadedData++

      let durationType = response.data.data.attributes['out-of-stock-scheduled-hours'];
      console.log('durationType', durationType);
      if (['1', '4', '__next_business_day__'].includes(durationType)) {
        this.durationType = durationType;
      } else if(!['1', '4', '__next_business_day__', ''].includes(durationType)) {
        this.durationType = 'None';
        this.numOfHours = durationType;
      }
    });
  }
  getApps() {
    Concept.find().then((response: any) => {
      this.applications = response.data.data.map((data: any) => {
        return {
          id: data.id.toString(),
          label: data.label,
        };
      });
    });
  }

  async getConnections() {
    let { data } = await Connect.getConnections();
    this.connections = data.data;
  }

  connectionLabel(connection: any): string {
    return `${connection.attributes.aggregator.name} - ${connection.attributes.menu.label}`;
  }

  getEmployeeLocations() {
    if (!this.employeeLocations.length) {
      return Employee.find(this.getUser.id)
        .then((response) => {
          if (response.data.included) {
            this.employeeLocations = response.data.included;
          }
        });
    }
    return Promise.resolve();
  }

  private get isMenuRestaurant(): boolean {
    const filterRole = ['menu', 'restaurant'];
    const roles = this.userRole.filter((role: string) => filterRole.find((frole: string) => role === frole));
    return !!roles?.length;
  }

  getLocations(page: 1) {
    Location.fetchLocations('en-us', page).then((response: any) => {
      // filter locations for menu and restaurant
      // SPO-94
      // [Web] Users :: Restaurant and Menu - Disable Locations
      // this.locations = response.data.data;
      this.locations = [...this.locations, ...response?.data?.data];

      if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
      ) {
        this.getLocations(response.data.meta.pagination.current_page + 1);
      }
      
      if (this.isMenuRestaurant) {
        this.getEmployeeLocations()
          .then(() => {
            this.locations = this.locations.filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id));
          });
      }
    });
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }


  updateItem(e: HTMLFormElement, isvalid: boolean) {
    // if (!isvalid) {
      if (!this.imageType) {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        MenuApi.updateItem(
          this.setData(),
          this.$route.params.id,
          this.$route.params.catId,
          this.$route.params.itemId
        )
          .then((response: any) => {
            const filtered = this.selectedLocations.filter((l: any) => !this.disabledLocations.find((k: any) => k === l));
            const toBeRemoved = this.disabledLocations.filter((d: any) => !this.selectedLocations.find((s: any) => s === d)) || [];
            const promiseArray = filtered.map((l: any) => {
              const payload = {
                items: [this.$route.params.itemId],
              };
              return Location.disableLocation(payload, l);
            });
            if (toBeRemoved.length) {
              toBeRemoved.forEach((t: any) => {
                promiseArray.push(Location.removeDisabledLocation(t, this.$route.params.itemId))
              });
            }

            return Promise.all(promiseArray);
          })
          .then(() => {
            this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            // this.$router.go(-1)
            location.reload()
            this.$router.push({
                  path: `/menus/${this.$route.params.id}/categories/${this.$route.params.catId}/items/${this.$route.params.itemId}/modifiers/1`,
            })
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.error.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      } else {
        const defaultText = e.submitter.innerHTML;
        this.waiting(
          e,
          '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        );
        MenuApi.updateItemFormdata(
          this.formData(),
          this.$route.params.id,
          this.$route.params.catId,
          this.$route.params.itemId
        )
        .then((response: any) => {
          this.imageType = 0;
          this.items[0].attributes["image-uri"] =
            response.data.data.attributes["image-uri"];
          const filtered = this.selectedLocations.filter((l: any) => !this.disabledLocations.find((k: any) => k === l));
          const toBeRemoved = this.disabledLocations.filter((d: any) => !this.selectedLocations.find((s: any) => s === d)) || [];
          const promiseArray = filtered.map((l: any) => {
            const payload = {
              items: [this.$route.params.itemId],
            };
            if (toBeRemoved.length) {
              toBeRemoved.forEach((t: any) => {
                promiseArray.push(Location.removeDisabledLocation(t, this.$route.params.itemId))
              });
            }
            return Location.disableLocation(payload, l);
          });

          return Promise.all(promiseArray);
        })
        .then(() => {
          this.restore(e, defaultText);
          this.$notify({
            title: "UPDATES SAVED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Changes have been saved successfully",
            type: "success",
            icon: "fas fa-check",
          });
          location.reload()
        })
        .catch((err: any) => {
          this.restore(e, defaultText);
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Something went wrong, please try again!",
            type: "danger",
            icon: "fas fa-bomb",
          });
        });
      }
    // }
  }
  formData() {
    let formData = new FormData();
    const isDiscountAllowed: any = this.items[0].attributes['is-discount-allowed'] ? 1 : 0;
    formData.append("code", this.items[0].attributes.code);
    formData.append("is-combo", this.items[0].attributes['is-combo']);
    formData.append("is-featured", this.items[0].attributes['is-featured']);
    formData.append("display-order", this.items[0].attributes["display-order"].toString());
    formData.append("enabled", this.items[0].attributes.enabled.toString());
    formData.append("price", this.items[0].attributes.price);
    formData.append("calories", this.items[0].attributes.calories);
    formData.append("disable-for-delivery", this.items[0].attributes['disable-for-delivery'])
    formData.append("disable-for-pickup", this.items[0].attributes['disable-for-pickup'])
    formData.append("is-discount-allowed", isDiscountAllowed)
    formData.append(
      "person-count-per-serving",
      this.items[0].attributes["person-count-per-serving"]
    );
    formData.append("calorie-count", this.items[0].attributes["calorie-count"]);

    for (let i in this.items) {
      formData.append(
        `name[${this.items[i].lang}]`,
        this.items[i].attributes.name ? this.items[i].attributes.name : ""
      );
      formData.append(
        `description[${this.items[i].lang}]`,
        this.items[i].attributes.description
          ? this.items[i].attributes.description
          : ""
      );
    }
    for (let i in this.selectedApplications) {
      formData.append(`apps[${i}]`, this.selectedApplications[i]);
    }

    let locations = this.selectedLocations
    if (this.isMenuRestaurant) {
      locations = [...this.selectedLocations, ...this.notBelongLocations];
    }

    for (let i in locations) {
      formData.append(`locations[${i}]`, locations[i]);
    }
    if (this.$refs.file.files[0]) {
      formData.append("image", this.$refs.file.files[0]);
    }

    let out_stock_locations = this.selectedOutOfStockLocations;
    formData.append("out-of-stock-locations", out_stock_locations);

    let hours = '';
    if (this.durationType != 'custom') {
      hours = this.durationType;
    } else {
      hours = this.numOfHours;
    }
    formData.append("out-of-stock-scheduled-hours", hours);
    
    return formData;
  }
  setData() {
    for (let i in this.items) {
      this.itemNames[this.items[i].lang] = this.items[i].attributes.name;
      this.itemDescs[this.items[i].lang] = this.items[i].attributes.description;
    }
    let price = {

    }
    if(this.items.length > 1) {
      this.items[1].attributes.price = this.items[0].attributes.price
      price = {
        price: this.items[1].attributes.price,
      }
    }

    let locations = this.selectedLocations;
    if (this.isMenuRestaurant) {
      locations = [...this.selectedLocations, ...this.notBelongLocations];
    }

    let out_stock_locations = this.selectedOutOfStockLocations;

    let prices = this.selectedLocationsPrice;

    let hours = '';
    if (this.durationType != 'custom') {
      hours = this.durationType;
    } else {
      hours = this.numOfHours;
    }

    return {
      prices,
      'is-combo': this.items[0].attributes["is-combo"],
      'is-featured': this.items[0].attributes["is-featured"],
      code: this.items[0].attributes.code,
      "display-order": this.items[0].attributes["display-order"],
      enabled: this.items[0].attributes.enabled,
      name: this.itemNames,
      description: this.itemDescs,
      ...price,
      calories: this.items[0].attributes.calories ? this.items[0].attributes.calories : '',
      "person-count-per-serving": this.items[0].attributes[
        "person-count-per-serving"
      ],
      "calorie-count": this.items[0].attributes["calorie-count"],
      apps: this.selectedApplications,
      "excluded-connections": this.selectedConnections.map((v: any) => parseInt(v)),
      locations,
      'out-of-stock-locations': out_stock_locations,
      "image-uri": this.items[0].attributes["image-uri"],
      'disable-for-delivery': this.items[0].attributes['disable-for-delivery'],
      'disable-for-pickup': this.items[0].attributes['disable-for-pickup'],
      'is-discount-allowed': this.items[0].attributes['is-discount-allowed'],
      'category-id': parseInt(this.categoryId),
      'out-of-stock-scheduled-hours': hours
    };
  }
  filesChange(e: any, errors: any) {
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];
    }
  }
}
