
import { Settings } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler } from '@/mixins'
@Component({
  mixins: [eventHandler]
})
export default class GlobalIngredientsList extends Vue {
  
  @Prop() items!: Array<Object>
  
  btnLoading!: Function
  btnRestore!: Function
  confirm!: Function
  $notify: any

  enableMsgBoxText(enabled: number) {
    return enabled ? 'Proceed enabling this item?' : 'Proceed disabling this item?'
  }

  get deleteMsgBoxText(): String {
    return 'Are you sure you want to delete this Ingredient?'
  }

  remove(id: string, enabled: number) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
          Settings.deleteIngredient(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
          .then((response: any) => {                        
            this.$emit('ingredient:changed')
            this.$notify({
              title: "DATA DELETED",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: 'Ingedient successfully deleted',
              type: "success",
              icon: "fas fa-trash",
            })
          })
          .catch((err: any) => {                
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          })
        }
      })
  }

  enable(id: string, enabled: number) {
    this.confirm(this.$bvModal, this.enableMsgBoxText(enabled))
      .then((value: boolean) => {
        if(value) {
          Settings.updateIngredient({enabled: enabled ? 1 : 0}, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
          .then((response: any) => {                        
            this.$emit('ingredient:changed')
            this.$notify({
              title: "DATA SAVED",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: enabled ? 'Ingedient enabled!' : 'Ingedient disabled',
              type: "success",
              icon: "fas fa-check",
            })
          })
          .catch((err: any) => {                
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          })
        }
      })
  }
}
