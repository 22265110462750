
import moment from "moment";
import { translations, notificationAlerts } from "@/mixins";
import Translations from "../components/Translations.vue";
import {Component, Vue, Watch} from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Promotion, Coupon, Customer } from "@/services/SOLO";
import { Table, TableColumn, Select, Option, Form, FormItem, Autocomplete } from 'element-ui';
import flatPicker from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { debounce } from 'vue-debounce';
import {mapGetters} from 'vuex';
import DigitalCouponQrCode from "../components/DigitalCouponQrCode.vue";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Translations,
    flatPicker,
    [Autocomplete.name]: Autocomplete,
    ValidationProvider,
    DigitalCouponQrCode
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: "account/getConceptSettings",
    }),

    daysOptions() {
      return [
        { value: 'monday', label: 'Monday' },
        { value: 'tuesday', label: 'Tuesday' },
        { value: 'wednesday', label: 'Wednesday' },
        { value: 'thursday', label: 'Thursday' },
        { value: 'friday', label:  'Friday' },
        { value: 'saturday', label: 'Saturday' },
        { value: 'sunday', label: 'Sunday' }
      ];
    },
  },
  mixins: [translations, notificationAlerts]
})
export default class CouponGroupsCouponCreate extends Vue {
  hasList: boolean = true;
  coupons: Array<any> = [];
  coupon: any = null;
  tab: number = 1;
  groupId: any = null;
  couponId: any = null;
  promotions: Array<any> = [];
  customers: Array<any> = [];
  successNotification!: Function;
  systemErrorNotification!: Function;
  generateBulk: boolean = false;
  isUploadCoupon: boolean = false;
  getLocale!: any;
  translate!: Function;
  getConceptSettings!: any
  isSavingCoupon: boolean = false
  promotionMeta: any = ""
  page: any = 1;
  modalForm: any = {
    'promotion-id': null,
    'subscription-reset-interval': null,
    'availability-days': [],
    code: '',
    'display-order': '',
    'terms-and-condition': {},
    name: {
      // primaryLang: '',
      // secondaryLang: '',
    },
    description: {
      // primaryLang: '',
      // secondaryLang: '',
    },
    'valid-from': null,
    'valid-to': null,
    'customer-id': null,
    quantity: null,
    image: null,
    type: '',
    remaining_codes: null,
    "duration-seconds": null
  };

  limitToSpecificCustomers: boolean = false;
  limitToSpecificCustomersField = null;
  enable: boolean = false;
  bulkCoupons: any = null;

  inStorePromo: boolean = false;
  isAvailabilityDays: boolean = false;
  imageUri: any = {
    // primaryLang: '',
    // secondaryLang: ''
  }

  dates: any = {
    from: {
      date: moment().utc().format('YYYY-MM-DD'),
      time: null,
    },
    to: {
      date: null,
      time: null,
    },
  };
  customerSelected: any = null;
  oldQuery: string = '';
  secondaryLang: string = '';
  primaryLang: string = '';
  arrayLang: Array<string> = [];
  promoType: string = '';
  schedules: any = [];
  hideCancelButton: boolean = false

  // @Watch('isAvailabilityDays', { deep: true })
  // onAvailabilityDaysChecked(value: boolean) {
  //   if (!value) {
  //     this.modalForm['availability-days'] = [];
  //   }
  // }

  @Watch('generateBulk', { deep: true })
  onChangeGenerateBulk(newVal: any) {
    if (newVal) {
      this.modalForm.prefix = '';
      this.modalForm['code-length'] = 0;
      this.modalForm.count = 0;

      delete this.modalForm.code;
    } else {
      delete this.modalForm.prefix;
      delete this.modalForm['code-length'];
      delete this.modalForm.count;

      this.modalForm.code = '';
    }
  }

  @Watch('promoType', { deep: true })
  onChangePromoType(newVal: any) {
    console.log('promoType', newVal);
    if (newVal == 'subscription') {
      this.limitToSpecificCustomers = true;
    } else {
      this.modalForm['subscription-reset-interval'] = null;
    }
  }

  @Watch('modalForm.quantity', { deep: true })
  onChangeQuantity(newVal: any) {
    if (newVal > this.modalForm['remaining_codes']) {
      extend("max_value", {
        message: this.translate(`Sorry you only have ${this.modalForm['remaining_codes']} remaining coupons.`),
      });
    }
  }

  @Watch('inStorePromo', { deep: true })
  onChangeInStorePromo(newVal: any) {
    if (newVal) {
      if (this.modalForm['promotion-id']) {
        this.checkPromotion();
      }
    } else {
      this.modalForm['availability-days'] = [];
    }
  }

  mounted() {
    this.schedules = [
      {value: 'weekly', text: 'Weekly'},
      {value: 'monthly', text: 'Monthly'},
      {value: 'yearly', text: 'Yearly'},
    ];
  }

  created() {
    console.log('params', this.$route.params, this.$route.query);
    const self = this;
    this.groupId = this.$route.params.id;
    this.couponId = this.$route.params.couponId;
    this.generateBulk = !!this.$route.query.bulk;

    this.secondaryLang = this.getConceptSettings.attributes['primary-language'] || '';
    this.primaryLang = this.getConceptSettings.attributes['secondary-language'] || '';

    this.arrayLang.push(this.secondaryLang);
    if (this.primaryLang) {
      this.arrayLang.push(this.primaryLang);
    }

    for (let i in this.arrayLang) {
      this.modalForm.name[this.arrayLang[i]] = "";
      this.modalForm.description[this.arrayLang[i]] = "";
      this.imageUri[this.arrayLang[i]] = "";
    }

    this.listOfPromotion(this.page);

    if (this.groupId) {
      Coupon.getCouponsbyCouponGroupId(this.groupId).then(response => {
        this.coupons = response.data.data;
      });
    }

    Customer.all().then(response => {
      console.log('Customer.all', response);
      this.customers = response.data.data;
      if (this.couponId) {
        let getCoupon: any = null;

        for (let i in this.arrayLang) {
          if (this.$route.query.digital) {
            getCoupon = Coupon.getDigitalCoupon(this.couponId, this.arrayLang[i]);
          } else {
            getCoupon = Coupon.getCoupon(this.groupId, this.couponId);
          }

          getCoupon
            .then((response: any) => {
              console.log("Coupon.getCoupon: ", response);
              this.coupon = response.data.data;
              const promotion = response.data.included[0];
              const customer = self.customers.find((cus: any) => {
                // console.log('cus: ', cus.id);
                // console.log('self.coupon.attributes["customer-id"]: ', self.coupon.attributes['customer-id']);
                return Number(cus.id) === self.coupon.attributes['customer-id']
              });
              console.log('formatDDate start: ', this.coupon.attributes['valid-from'], this.formatDDate(this.coupon.attributes['valid-from']));
              console.log('formatDDate end: ', this.coupon.attributes['valid-to'], this.formatDDate(this.coupon.attributes['valid-to']));
              const from = this.$route.query.digital ? this.formatDate(this.coupon.attributes['starts-at']).split(' ') : this.formatDDate(this.coupon.attributes['valid-from']).split(' ');
              const to = this.$route.query.digital ? this.formatDate(this.coupon.attributes['ends-at']).split(' ') : this.formatDDate(this.coupon.attributes['valid-to']).split(' ');
              console.log('from: ', from);
              console.log('to: ', to);
              if (this.coupon.attributes['customer-id']) {
                this.limitToSpecificCustomers = true;
              }
              console.log("this.coupon: ", this.coupon);
              console.log('this.customers: ', this.customers);
              console.log("customer: ", customer);
              if (customer) {
                this.customerSelected = {
                  value: `${customer.attributes['first-name']} ${customer.attributes['last-name']} - ${customer.attributes['email']}`,
                  id: customer.id,
                }
              }

              console.log({ coupon: this.coupon.attributes });
              this.modalForm.description[this.arrayLang[i]] = this.$route.query.digital ? this.coupon.attributes.description : '';
              this.modalForm.name[this.arrayLang[i]] = this.$route.query.digital ? this.coupon.attributes.name : '';
              this.modalForm['customer-id'] = customer && customer.attributes['first-name'] + ' ' + customer.attributes['last-name'] + '-' + customer.attributes['email'];
              this.modalForm.code = this.coupon.attributes.code;
              this.modalForm['promotion-id'] = promotion.id;
              this.modalForm['subscription-reset-interval'] = this.coupon.attributes['subscription-reset-interval'];
              this.promoType = this.modalForm['subscription-reset-interval'] ? 'subscription' : '';
              this.inStorePromo = this.coupon.attributes.type == 'in-store' ? true : false;
              this.modalForm['remaining_codes'] = this.coupon.attributes.remaining_codes;
              this.modalForm['duration-seconds'] = this.coupon.attributes['duration-seconds'];
              this.modalForm['terms-and-condition'][this.arrayLang[i]] = this.$route.query.digital ? this.coupon.attributes['terms-and-condition'] : '' 
              this.modalForm['display-order'] = this.coupon.attributes['display-order'];
              this.dates.from.date = from[0];
              this.dates.from.time = from[1];
              this.dates.to.date = to[0];
              this.dates.to.time = to[1];
              console.log('this.dates: ', this.dates);
              this.imageUri[this.arrayLang[i]] = this.coupon.attributes['image-uri'];

              if (!this.$route.query.digital) { // for not digital coupon
                const { name } = this.coupon.attributes;
                this.modalForm.name[this.arrayLang[i]] = name[this.arrayLang[i]];
              } 

              // if (this.coupon.attributes['availability-days'] && this.$route.query.digital) {
              //   this.isAvailabilityDays = true
              //   this.modalForm['availability-days'] = this.coupon.attributes['availability-days'] || [];
              // }

              if (this.$route.query.digital) {
                this.checkPromotion();
              }
            })
        }
      }
    });
  }

  listOfPromotion(page: number = 1) {
  Promotion.fetchPromotions(page).then(response => {
    this.promotions = [...this.promotions, ...response.data.data];
    console.log("PromotionsList: ", response.data.meta.pagination);
    this.promotionMeta = response.data.meta.pagination;
    
    if (response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages) {
      this.listOfPromotion(page + 1);
    }
  });
}


  checkPromotion(){
    const promo = this.getSelectedPromotion()

    this.modalForm['availability-days'] = [];
    if (promo && promo.attributes['availability-rules']) {
      const { schedule } = promo.attributes['availability-rules'];
      this.getAvailabilityRules(schedule);
    } else {
      this.getAvailabilityRules(null);
    }
  }

  getSelectedPromotion() {
    return this.promotions.filter((promotion: any) => {
      return promotion.id === this.modalForm['promotion-id'];
    })[0];
  }

  setAvailabilityDays(days: Array<String>, options: any) {
    Object.entries(options).forEach(([key, value]) => {
      if (days.includes(key)) {
        this.modalForm['availability-days'].push(value);
      }
    });
  }

  getAvailabilityRules(schedule: any) {
    const daysOptions = {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday',
    }
    if (schedule && Object.keys(schedule).length) {
      let availableDays = Object.keys(schedule).filter((day: any) => {
        return schedule[day].available ? day : null
      });

      this.setAvailabilityDays(availableDays, daysOptions);

    } else {
      let availableDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
      this.setAvailabilityDays(availableDays, daysOptions);
    }
  }

  getPromotionType() {
    this.checkPromotion();

    this.promoType = this.promotions.find((promo: any) => {return promo.id == this.modalForm['promotion-id']}).attributes.type;

    return this.promoType;
  }

  checkSize(text: any) {
    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    } else {
      if(text === 'The image field size must be less than 2000KB') {
        return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
      }
    }
  }

  formatDate(date: string, separator: string = 'T') {
    let split = date.split(separator);
    const d: any = split[0];
    let time: any = split[1].split('.');
    time = time[0];
    return this.formatDDate(`${d} ${time}`);
  }

  formatDDate(date: string) {
    console.log(moment.locale());
    return moment
      .utc(date)
      .locale("en-us")
      .local()
      .format("YYYY-MM-DD HH:mm:ss");
  }

  onCancel() {
    if (this.$route.query.digital) {
      this.$router.push({ path: `/coupon-groups/coupon-list/digital?digital=true` })
    } else {
      this.$router.push({ path: `/coupon-groups/coupon-list/${this.$route.params.id}` });
    }
  }

  generateQrCodes() {
    let quantity = this.modalForm['quantity'] || 0;
    Coupon.updateDigitalCouponQuantity(Number(this.$route.params.couponId), quantity)
      .then(() => {
        this.modalForm['quantity'] = null;
        this.modalForm['remaining_codes'] += parseInt(quantity);
      });
  }

  async onAddCoupon() {

    if (this.bulkCoupons) {
      this.$router.push({ path: `/coupon-groups/coupon-list/${this.$route.params.id}` });
      return;
    }

    if (!this.dates.to.date) {
      this.systemErrorNotification('CREATE ERROR!', 'Need to select an End date');
      return;
    }

    let toTime, fromTime;
    
    if (this.dates.to.time === null || this.dates.to.time === undefined || this.dates.to.time === '') {
      this.dates.to['time'] = '11:59 PM'
    }

    if (this.dates.from.time === null || this.dates.from.time === undefined || this.dates.from.time === '') {
      this.dates.from['time'] = '00:00 AM'
    }

    if (this.dates.to['time'].includes('PM')) {
      let tempToTime = this.dates.to['time'].split(':')
      if (tempToTime[0] !== '12') {
        toTime = +tempToTime[0]
        toTime += 12;
        tempToTime[0] = toTime + '';
        tempToTime = tempToTime.join(':')
        this.dates.to['time'] = tempToTime.replace('PM', '').trim();;
      } else {
        this.dates.to['time'] = tempToTime.join(':').replace('PM', '').trim();
      }
    }

    if (this.dates.to['time'].includes('AM')) {
      this.dates.to['time'] = this.dates.to['time'].replace('AM', '').trim();
      let time = this.dates.to['time'].split(':');
      if (time[0] === '12') {
        time[0] = '00';
        this.dates.to['time'] = time.join(':');
      } 
    }

    if (this.dates.from['time'].includes('AM')) {
      this.dates.from['time'] = this.dates.from['time'].replace('AM', '').trim();
      let time = this.dates.from['time'].split(':');
      if (time[0] === '12') {
        time[0] = '00';
        this.dates.from['time'] = time.join(':');
      } 
    }

    if (this.dates.from['time'].includes('PM')) {
      let tempFromTime = this.dates.from['time'].split(':')
      if (tempFromTime[0] !== '12') {
        fromTime = +tempFromTime[0]
        fromTime += 12;
        tempFromTime[0] = fromTime + '';
        tempFromTime = tempFromTime.join(':')
        this.dates.from['time'] = tempFromTime.replace('PM', '').trim();;
      } else {
        this.dates.from['time'] = tempFromTime.join(':').replace('PM', '').trim();
      }
    }

    if (this.dates.from.date) {
      this.modalForm['valid-from'] = moment(`${this.dates.from.date} ${this.dates.from.time ? this.dates.from.time : '00:00'}:00`).utc().format('YYYY-MM-DD H:mm:ss');
    }

    this.modalForm['valid-to'] = moment(`${this.dates.to.date} ${this.dates.to.time ? this.dates.to.time : '00:00'}:00`).utc().format('YYYY-MM-DD H:mm:ss');
    if (this.customerSelected) {
      if (!this.limitToSpecificCustomers) {
        this.customerSelected = null;
        delete this.modalForm['customer-id'];
      }
      this.modalForm['customer-id'] = this.customerSelected.id;
    } else {
      delete this.modalForm['customer-id'];
    }

    this.isSavingCoupon = true

    if (this.$route.query.digital) {
      let isErrorImg = false
      if (this.secondaryLang) {
        /* @ts-ignore */
        if (this.$refs.file2.files[0]) {
          let formData = new FormData();
          /* @ts-ignore */
          formData.append('image', this.$refs.file2.files[0]);
          await Coupon.uploadImage(formData).then((response) => {
            this.imageUri[this.secondaryLang] = response.data.data.attributes.url;
          }).catch((error) => {
            isErrorImg = true
            this.isSavingCoupon = false
            this.systemErrorNotification('Image Coupon Error!');
          })
        }
      }
      if (this.primaryLang) {
        if (this.$refs.file.files[0]) {
          let formData = new FormData();
          formData.append('image', this.$refs.file.files[0]);
          await Coupon.uploadImage(formData).then((response) => {
            this.imageUri[this.primaryLang] = response.data.data.attributes.url;
          }).catch((error) => {
            isErrorImg = true
            this.isSavingCoupon = false
            this.systemErrorNotification('Image Coupon Error!');
          })
        }
      }
      const payload = {
        "name": {
          [this.primaryLang]: this.modalForm.name[this.primaryLang],
          [this.secondaryLang]: this.modalForm.name[this.secondaryLang]
        },
        "description": {
          [this.primaryLang]: this.modalForm.description[this.primaryLang] ?? '',
          [this.secondaryLang]: this.modalForm.description[this.secondaryLang] ?? '',
        },
        "terms-and-condition": {
          [this.primaryLang]: this.modalForm['terms-and-condition'][this.primaryLang] ?? '',
          [this.secondaryLang]: this.modalForm['terms-and-condition'][this.secondaryLang] ?? '',
        },
        "availability-days": this.modalForm['availability-days'],
        "display-order": this.modalForm['display-order'],
        "duration-seconds": Number(this.modalForm['duration-seconds']),
        "type": this.inStorePromo ? "in-store" : null,
        "starts-at": this.modalForm['valid-from'],
        "ends-at": this.modalForm['valid-to'],
        "customer-id": this.customerSelected ? Number(this.customerSelected.id) : null,
        "promotion-id": this.modalForm['promotion-id'] ? Number(this.modalForm['promotion-id']) : null,
        "subscription-reset-interval": this.modalForm['subscription-reset-interval'] || null,
        "image-uri": {
          [this.primaryLang]: this.imageUri[this.primaryLang] || '',
          [this.secondaryLang]: this.imageUri[this.secondaryLang] || ''
        },
      };
      let digitalCouponId = null;
      if (!isErrorImg) {
        if (this.couponId) {
          await Coupon.updateDigitalCoupon(payload, this.couponId).then((response) => {
            // if (this.inStorePromo && this.modalForm['quantity']) {
            //   Coupon.updateDigitalCouponQuantity(Number(response.data.data.id), this.modalForm['quantity']).then(() => {});
            // }-=
            this.isSavingCoupon = false
            this.successNotification('DIGITAL COUPON UPDATED!', 'coupon successfully updated!');
            this.$router.push({ path: `/coupon-groups/coupon-list/digital?digital=true` });
          })
          .catch((err) => {
            console.log('update err: ', err.response.data.messages);
            let msg = '';
            if (
              err
              && err.response
              && err.response.data
              && err.response.data.messages
            ) {
              Object.keys(err.response.data.messages).forEach(key => {
                console.log(key);
                msg += err.response.data.messages[key] + '\n';
              });
              msg = msg || 'An Error Occured on Updating Coupon/s.'
              this.systemErrorNotification('UPDATE ERROR!', msg);
            }
            this.isSavingCoupon = false
          });
        } else {
          await Coupon.createDigitalCoupon(payload).then((response) => {
            /* @ts-ignore */
            analytics.track('create_digital_coupon', {
              
            });
            // if (this.inStorePromo && this.modalForm['quantity']) {
            //   Coupon.updateDigitalCouponQuantity(Number(response.data.data.id), this.modalForm['quantity']).then(() => {});
            // }
            this.isSavingCoupon = false
            this.successNotification('DIGITAL COUPON CREATED!', 'coupon successfully created!');
            this.$router.push({ path: `/coupon-groups/coupon-list/digital?digital=true` });
          })
          .catch((err) => {
            console.log('create err: ', err.response.data.messages);
            let msg = '';
            if (
              err
              && err.response
              && err.response.data
              && err.response.data.messages
            ) {
              Object.keys(err.response.data.messages).forEach(key => {
                console.log(key);
                msg += err.response.data.messages[key] + '\n';
              });
              msg = msg || 'An Error Occured on Creating Coupon/s.'
              this.systemErrorNotification('CREATE ERROR!', msg);
            }
            this.isSavingCoupon = false
          });
        }
      }
    } else {
      this.modalForm['code-length'] = Number(this.modalForm['code-length']);
      this.modalForm['count'] = Number(this.modalForm['count']);

      if (this.couponId) { // edit coupon group coupon
        this.modalForm['description'] = {...this.modalForm['name']}
        Coupon.updateCoupon(this.modalForm, this.groupId, this.couponId).then(() => {
          this.isSavingCoupon = false
          this.successNotification('COUPON UPDATED!', 'coupon successfully updated!');
          this.$router.push({ path: `/coupon-groups/coupon-list/${this.$route.params.id}` });
        })
        .catch((err) => {
          console.log('update err: ', err);
          let msg = '';
          if (
            err
            && err.response
            && err.response.data
            && err.response.data.messages
          ) {
            Object.keys(err.response.data.messages).forEach(key => {
              console.log(key);
              msg += err.response.data.messages[key] + '\n';
            });
            msg = msg || 'An Error Occured on Updating Coupon/s.'
            this.systemErrorNotification('UPDATE ERROR!', msg);
          }
          this.isSavingCoupon = false
        });
      } else { // create coupon group coupon
        this.modalForm['description'] = {...this.modalForm['name']}

        if (this.generateBulk) {
          const isErrorPayload = this.checkPayloadFormIfHasAnError(this.modalForm)

          if (isErrorPayload) {
            this.isSavingCoupon = false 
            return this.systemErrorNotification('CREATE ERROR!', 'Please fill up the form');
          }
        } else {
          if (this.isObjectKeyValueEmpty(this.modalForm.name, 'ar-sa') || this.isObjectKeyValueEmpty(this.modalForm.name, 'en-us')) {
            this.isSavingCoupon = false
            return this.systemErrorNotification('CREATE ERROR!', 'Please fill up the form');
          }

          if (!this.modalForm['promotion-id'] || !this.modalForm.code) {
            this.isSavingCoupon = false
            return this.systemErrorNotification('CREATE ERROR!', 'Please fill up the form');
          }
        }
        
        Coupon.createCoupon(this.modalForm, this.groupId).then((response) => {
          /* @ts-ignore */
          analytics.track('create_coupon', {
            
          });
          console.log('createCoupon: ', response);
          this.isSavingCoupon = false
          this.successNotification('COUPON CREATED!', 'coupon successfully created!');
          if (this.generateBulk) {
            this.hideCancelButton = true
            this.bulkCoupons = response.data.data;
          } else {
            this.$router.push({ path: `/coupon-groups/coupon-list/${this.$route.params.id}` });
          }
        })
        .catch((err) => {
          console.log('create err: ', err.response.data.messages);
          let msg = '';
          if (
            err
            && err.response
            && err.response.data
            && err.response.data.messages
          ) {
            Object.keys(err.response.data.messages).forEach(key => {
              console.log(key);
              msg += err.response.data.messages[key] + '\n';
            });
            msg = msg || 'An Error Occured on Creating Coupon/s.'
            this.systemErrorNotification('CREATE ERROR!', msg);
          }
          this.isSavingCoupon = false
        });
      }
    }
  }

  isObjectKeyValueEmpty(obj: any, key: any): boolean { // check if the key value is empty or not
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === null || value === undefined || value === '') {
        return true;
      } else if (typeof value === 'object' && Object.keys(value).length === 0) {
        return true;
      }
    }
    return false;
  }

  checkPayloadFormIfHasAnError(form: any): boolean {
    if (this.isObjectKeyValueEmpty(form.name, 'ar-sa') || this.isObjectKeyValueEmpty(form.name, 'en-us')) {
      return true
    }
    if (form.prefix === '' || form.prefix === null || form.prefix === undefined) {
      return true
    }
    if (!form.count) {
      return true
    }
    if (!form['code-length']) {
      return true
    }
    return false
  }

  handleSelect(e: any) {
    console.log('e: ', e);
    this.customerSelected = e;
  }

  onFetch(queryString: string, cb: Function) {
  const filterType = ['customer'];
  const promiseArray: any = [];

  if (!queryString || this.oldQuery === queryString) {
    return;
  }
  this.oldQuery = queryString;

  debounce(() => {
    filterType.forEach(filter => {
      promiseArray.push(Customer.filterByType(queryString, filter));
    });

    Promise.all(promiseArray)
      .then((responses) => {
        console.log('onFetch: ', responses);
        let customerArr: any = [];

        responses.forEach((response: any) => {
          if (response.data.data.length) {
            customerArr = [...customerArr, ...response.data.data];
          }
        });

        let formattedCustomers;
        
        if (customerArr.length === 0) {
          // If no customers found, return a "customer not found" message
          formattedCustomers = [{
            value: this.translate('Customer not found'),
            id: null
          }];
        } else {
          // Otherwise, format the customer data as usual
          formattedCustomers = customerArr.map((customer: any) => {
            return {
              value: `${customer.attributes['first-name']} ${customer.attributes['last-name']} - ${customer.attributes['email']} - ${customer.attributes['mobile'] ? customer.attributes['mobile'] : 'No phone number'}`,
              id: customer.id,
            };
          });
        }

        cb(formattedCustomers);
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
        cb([{
          value: 'Error fetching data',
          id: null
        }]);
      });
  }, 700)();
}



  getPromotion(coupon: any) {
    return this.promotions.find((promotion: any) => {
      return Number(promotion?.id) === Number(coupon?.relationships?.promotion?.data?.id);
    });
  }

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
}
