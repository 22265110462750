
import _ from "lodash";
import {
  Component,
  Watch,
  Prop,
  PropSync,
  Mixins
} from "vue-property-decorator";
import {Form} from "element-ui";
import Translations from "@/mixins/translations";
import {Loyalty as LoyaltyService, Category } from "@/services/SOLO";
import { Chrome } from 'vue-color';
import {Select, Option} from "element-ui";
import {ValidationProvider} from "vee-validate";
import { mapGetters } from 'vuex';

@Component<TierSingleModal>({
  components: {
    [Form.name]: Form,
    colorPicker: Chrome,
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getLocale: 'app/getLocale'
    }),

    isArabic(){
      return this.getLocale === 'ar_SA';
    }
  }
})
export default class TierSingleModal extends Mixins(Translations) {
  @Prop({type: Boolean, default: false}) readonly value!: boolean;
  @PropSync("tier", {type: Object, default: null}) syncedTier!: any;

  form: any = {
    name: {
      "en-us": "",
      "ar-sa": ""
    },
    "start-point": 0,
    "end-point": 0,
    "collect-rules": {
      "earn-point": 0,
      "per-sar": 0
    },
    "redeem-rules": {
      "earn-sar": 0,
      "per-point": 0,
    },
    "color": "",
    "image-url": "",
  };
  colorShow: boolean = false;
  selectedType: number = 2;
  imageTypes: Array<Object> = [
    {value: 0, text: "Upload IMG",},
    {value: 1, text: "Image URL",},
    {value: 2, text: "Stock Images"},
  ];
  fileValue: any = null;
  imageUri: string = '';
  searchStock: string = 'Loyalty Card';
  stockImages: any = [];
  isSaving: boolean = false;

  @Watch("selectedType")
  onPropertyChanged(value: number, oldValue: number) {
    if (value === 2) {
      this.searchStock = 'Loyalty Card'
    }
    this.stockImages = []
  }

  @Watch("tier")
  onTierChanged(value: any, oldValue: any) {
    if (value) {
      if (value['image-type']) {
        if (value['image-type'] == 'Upload IMG') {
          this.selectedType = 0
        } else if (value['image-type'] == 'Image URL') {
          this.selectedType = 1
        } else if (value['image-type'] == 'Stock Images') {
          if (value['image-url']) {
            this.imageUri = value['image-url']
            this.stockImages = [...this.stockImages, { attributes: { 'image-uri': value['image-url'] }}]
          }
          this.selectedType = 2
        }
      }
    }
  }

  selectStockImage(stockImage: any) {
    this.imageUri = stockImage;
  }

  async searchStockImages(e: any) {
    try {
      const searchFilter = this.searchStock.toLowerCase();
      const images = await Category.searchStockImgs(searchFilter)
      this.stockImages = images.data.data;
      if (this.stockImages === [] || this.stockImages.length === 0) {
        this.imageUri = ''
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  setColor(color: any) {
    this.form.color = color.hex
  }

  toggle() {
    this.colorShow = !this.colorShow;
  }

  checkSize(text: any) {
    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }
  }

  async onSave() {
    if (this.syncedTier) {
      await this.updateTier();
    } else {
      await this.createTier();
    }

    this.closeModal();
    this.resetForm();
  }

  async createTier() {
    this.isSaving = true
    let formData = new FormData();

    if (this.fileValue) {
      formData.append(`name[en-us]`, this.form["name"]["en-us"]);
      formData.append(`name[ar-sa]`, this.form["name"]["ar-sa"]);
      formData.append(`color`, this.form["color"]);
      formData.append(`start-point`, this.form["start-point"]);
      formData.append(`end-point`, this.form["end-point"]);
      formData.append(`collect-rules[earn-point]`, this.form["collect-rules"]["earn-point"]);
      formData.append(`collect-rules[per-sar]`, this.form["collect-rules"]["per-sar"]);
      formData.append(`redeem-rules[earn-sar]`, this.form["redeem-rules"]["earn-sar"]);
      formData.append(`redeem-rules[per-point]`, this.form["redeem-rules"]["per-point"]);
      formData.append(`image-url`, this.fileValue);

      this.form = formData;
    } else {
      this.form["start-point"] = parseInt(this.form["start-point"]);
      this.form["end-point"] = parseInt(this.form["end-point"]);
      this.form["collect-rules"]["earn-point"] = parseInt(
          this.form["collect-rules"]["earn-point"]
      );
      this.form["collect-rules"]["per-sar"] = parseInt(
          this.form["collect-rules"]["per-sar"]
      );
      this.form["redeem-rules"]["earn-sar"] = parseInt(
          this.form["redeem-rules"]["earn-sar"]
      );
      this.form["redeem-rules"]["per-point"] = parseInt(
          this.form["redeem-rules"]["per-point"]
      );
      this.form["image-url"] = this.imageUri;
      let imageType: any = this.imageTypes.filter((type: any) => type.value === this.selectedType)[0];
      this.form["image-type"] = imageType.text
    }
    try {
      let { data } = await LoyaltyService.createTier(this.form);
      data.data["name"] = this.form["name"];
      this.$emit("add:tier", data.data);
      this.$notify({
        title: `CREATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Tier was created successfully!`,
        type: "success"
      } as any);
      this.isSaving = false
    } catch (Error) {
      console.log('Error in Creation of Tier', Error);
    }
  }

  async updateTier() {
    this.isSaving = true
    let formData = new FormData();

    if (this.fileValue) {
      formData.append(`name[en-us]`, this.form["name"]["en-us"]);
      formData.append(`name[ar-sa]`, this.form["name"]["ar-sa"]);
      formData.append(`color`, this.form["color"]);
      formData.append(`start-point`, this.form["start-point"]);
      formData.append(`end-point`, this.form["end-point"]);
      formData.append(`collect-rules[earn-point]`, this.form["collect-rules"]["earn-point"]);
      formData.append(`collect-rules[per-sar]`, this.form["collect-rules"]["per-sar"]);
      formData.append(`redeem-rules[earn-sar]`, this.form["redeem-rules"]["earn-sar"]);
      formData.append(`redeem-rules[per-point]`, this.form["redeem-rules"]["per-point"]);
      formData.append(`image-url`, this.fileValue);

      this.form = formData;
    } else {
      this.form["start-point"] = parseInt(this.form["start-point"]);
      this.form["end-point"] = parseInt(this.form["end-point"]);
      this.form["collect-rules"]["earn-point"] = parseInt(
          this.form["collect-rules"]["earn-point"]
      );
      this.form["collect-rules"]["per-sar"] = parseInt(
          this.form["collect-rules"]["per-sar"]
      );
      this.form["redeem-rules"]["earn-sar"] = parseInt(
          this.form["redeem-rules"]["earn-sar"]
      );
      this.form["redeem-rules"]["per-point"] = parseInt(
          this.form["redeem-rules"]["per-point"]
      );
      this.form["image-url"] = this.imageUri;
      let imageType: any = this.imageTypes.filter((type: any) => type.value === this.selectedType)[0];
      this.form["image-type"] = imageType.text
    }

    try {
      await LoyaltyService.updateTier(this.syncedTier.id, this.form);
      let {data} = await LoyaltyService.updateTier(
          this.syncedTier.id,
          this.form
      );
      for (let key in this.form) {
        if (typeof this.syncedTier[key] != 'undefined') {
          this.syncedTier[key] = this.form[key];
        }
      }

      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Tier data was updated successfully!`,
        type: "success"
      } as any);
      this.isSaving = false
    } catch (Error) {
      console.log('Error in Creation of Tier', Error);
    }
  }

  resetForm() {
    this.form = {
      name: {
        "en-us": "",
        "ar-sa": ""
      },
      "start-point": 0,
      "end-point": 0,
      "collect-rules": {
        "earn-point": 0,
        "per-sar": 0
      },
      "redeem-rules": {
        "earn-sar": 0,
        "per-point": 0
      },
      "color": "",
      "image-url": "",
    };

    this.selectedType = 0;

    this.fileValue = null;
    this.imageUri = '';

  }

  closeModal() {
    this.isOpen = false;
  }

  get isOpen() {
    return this.value;
  }

  set isOpen(value) {
    this.stockImages = []
    this.$emit("input", value);
  }

  get disableForm() {
    return false;
  }

  @Watch("syncedTier")
  onSyncedTierChange(newVal?: any) {
    if (newVal) {
      this.fileValue = null;
      this.imageUri = newVal['image-url'];
      this.imageTypes = [
        { value: 0, text: "Upload IMG" },
        { value: 1, text: "Image URL" },
        { value: 2, text: "Stock Images"},
      ]

      for (let key in newVal) {
        if (newVal.name.length == 0) {
          continue;
        }

        if (typeof this.form[key] != 'undefined') {
          this.form[key] = _.cloneDeep(newVal[key]);
        }
      }
    } else {
      this.resetForm();
    }
  }
}
