
import { User } from "@/interfaces/Landing";
import { Auth, FoodicsAuth } from "@/services/core";
import { Concept as ConceptApi, Menu, SettingsConcepts, Gainsight } from '@/services/SOLO'
import { ValidationObserver, configure } from "vee-validate";
import { mapGetters, mapMutations } from "vuex";
import { eventHandler, translations } from "@/mixins";
import WelcomeModal from "./components/WelcomeModal.vue";
import PermissionModal from "./components/PermissionModal.vue";
import {
  Component,
  Prop,
  Vue,
  Watch,
  Ref,
  Mixins,
} from "vue-property-decorator";
import authService from "@/services/core/auth.service";
import { Subscription } from "@/services/SOLO";
import { Concept } from "@/models";
import store from "@/store";
import moment from "moment";

@Component({
  components: { WelcomeModal, PermissionModal },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
      getUserRole: "account/getUserRole",
      isLoggedIn: "account/isLoggedIn",
      activeConcept: 'account/activeConcept',
      activeLanguage: 'account/activeLanguage',
      getUserClient: 'account/getUserClient',
      getConceptSettings: 'account/getConceptSettings'
    }),
  },
  methods: {
    ...mapMutations({
      setUser: "account/setUser",
      setUser2: "account/setUser2",
      setUserRole: "account/setUserRole",
      setSubscription: 'account/setSubscription',
      setConceptSettings: 'account/setConceptSettings',
      setUserConcept: 'account/setUserConcept',
      setUserLanguage: 'account/setUserLanguage',
      setMenus: 'menu/setMenus',
      setQRUri: 'tfa/setQRUri',
      setSecretKey: 'tfa/setSecretKey',
      setTfaToken: 'tfa/setTfaToken',
    }),
  },
  mixins: [eventHandler, translations],
})
export default class Home extends Vue {
  private getUserClient: any
  activeConcept!: Concept
  activeLanguage!: Concept
  translate: any
  activateScript: any = false
  setUser!: Function;
  setUser2!: Function;
  setUserRole!: Function;
  setMenus!: Function
  setQRUri!: Function
  setSecretKey!: Function
  setTfaToken!: Function
  getUser!: typeof mapGetters;
  getUserRole!: typeof mapGetters;
  getConceptSettings!: any;
  notify: boolean = false;
  model: User = {
    username: "",
    password: "",
    rememberMe: false,
  };
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    welcomeModal: WelcomeModal;
    permissionModal: PermissionModal;
  };
  waiting!: Function;
  restore!: Function;
  setSubscription!: Function
  setConceptSettings!: Function
  setUserConcept!: Function
  setUserLanguage!: Function
  isLoggedIn!: Function
  errLogin: string = ''
  constructor() {
    super();
  }

  mounted() {
    if(this.isLoggedIn) {
      this.$router.push({name: 'dashboard'})
    }
    if(this.getClientUserLabel === 'Foodics Online') {
      this.activateScript = true;
    }
  }

  public get logo(): string {
    if(this.getUserClient) {
      return this.getUserClient.attributes['logo-uri']
    }
    return ``
  }
  

  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }

  public get hasFoodicsRegister(): Boolean {
    if(this.getUserClient) {
      if(this.getUserClient.attributes.auth.split(',').includes('foodics')) {
        return true
      }
      return false
    }
    return false
  }

  private get hasSoloRegister(): Boolean {
    if(this.getUserClient) {
      if(this.getUserClient.attributes.auth.split(',').includes('solo-register')) {
        return true
      }
      return false
    }
    return false
  }

  async created() {
    let code : string = this.$route.query.code as string;
    if (code) {
      try {
        let response = await FoodicsAuth.getToken(code);
        // this.$refs.welcomeModal.notify = true;
        // this.$refs.welcomeModal.success = true;
      } catch (error) {
        console.log(error);
        this.$refs.welcomeModal.notify = true;
        this.$refs.welcomeModal.success = false;
      }
    }
  }

  async authenticate(e: any) {
    this.errLogin = ''
    const defaultText = e.target.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Signing in')
    Auth.login(this.model).then(async (response: any) => {
      await this.setUserConcept(response.data.data.attributes.concepts[0])
      await this.setUser(response.data.data)
      await this.setUser2(response.data.data)
      await this.setUserRole(response.data.data.attributes.roles)
      let concept: any
      await SettingsConcepts.get().then(async(response: any) => {
        concept = response.data.data
      }).catch((err: any) => {
        console.log(err)
      })

      if (concept.attributes['is-2fa-enabled']) {
        if (!response.data.data['attributes']['tfa-factors'].length) {
          // Auth.getTOTP().then((response: any) => {
          //   this.setQRUri(response.data['qr-uri'])
          //   this.setSecretKey(response.data['secret-key'])
          //   this.setTfaToken(response.data['tfa-token'])

            // delete user object temporarily to proceed with 2FA setup
            // restricts from accessing dashboard
            this.setUser(null)

            this.$router.push({ name: 'account-protected' })
          // }).catch((err: any) => {
          //   console.log(err)
          //   this.errLogin = err.response.data.error[0].detail
          // })
        }
      } else {
        this.getSubscriptionFn(response.data.data, response.data.data.attributes.roles)
        this.getConcepts()

        var gainsightDetails: any = null;
        Gainsight.details().then((result: any) => {
          gainsightDetails = result;
          let unixSignUp = moment(result.data['sign-up-date']).unix()
          let unixLastSeen = Date.now()
          console.log('unixSignUp', unixSignUp)
          console.log('unixLastSeen', unixLastSeen)
          //passing user and account objects:
          /* @ts-ignore */
          window.aptrinsic("identify",
            {
              //User Fields
              "id": response.data.data.id, // Required for logged in app users
              "email": result.data['email'],
              "firstName": response.data.data['first-name'],
              "lastName": response.data.data['last-name'],
              "phone": result.data['phone-number'], //unix time in ms
              "signUpDate": unixSignUp, //unix time in ms
              "lastSeen": unixSignUp, //unix time in ms
              "countryCode": result.data['country'],
              "accountId": result.data['f5-number'],
              "timeZone": result.data['time-zone'],
              "username": result.data['customer-name'],
            },
            // {
            //   //Account Fields
            //   "id": this.activeConcept.id, //Required
            //   "name": this.activeConcept.attributes.label,
            // }
          );

          /* @ts-ignore */
          analytics.identify(result.data['f5-number'], {
            name: result.data['customer-name'],
            email: result.data['email']
          });
        }).catch((err: any) => {
          // 
        })

        /* @ts-ignore */
        Upscope('updateConnection', {
          // Set the user ID below. If you don't have one, set to undefined.
          uniqueId: gainsightDetails?.data?.['f5-number'],

          // Set the customer name or email below (e.g. ["John Smith", "john.smith@acme.com"]).
          identities: [
            gainsightDetails?.data?.['customer-name'], 
            gainsightDetails?.data?.['email']
          ]
        });
      }
      this.restore(e, defaultText)


      // this.setUserConcept(response.data.data.attributes.concepts[0])
      // this.setUser(response.data.data)
      // this.setUserRole(response.data.data.attributes.roles)
      // await SettingsConcepts.get().then(async (response: any) => {
      //   await this.setConceptSettings(response.data.data)
      // }).catch((err: any) => {
      //   console.log(err)
      // })
      // if (this.getConceptSettings.attributes['is-2fa-enabled']) {
      //   // if (response.data.data.attributes['tfa-factors'].length) {
      //   //   this.$router.push({ name: 'code-verifier' })
      //   // } else {
      //     this.$router.push({ name: 'account-protected' })
      //   // }
      // } else {
      //   this.getSubscriptionFn(response.data.data, response.data.data.attributes.roles)
      //   this.getConcepts()
      // }
      // this.restore(e, defaultText)
    }).catch((err: any) => {
      console.log(err.response.data)
      if (err.response.data.error[0].code == 2403) {
        // this.setUserConcept({})
        // this.setUser(null)
        // this.setUserRole([])
        this.setTfaToken(err.response.data.messages[0]['tfa-token'])
        this.$router.push({ name: 'code-verifier' })
      }

      this.restore(e, defaultText)
      this.errLogin = err.response.data.error[0].detail
    })
  }

  async getConcepts() {
      SettingsConcepts.get().then((response: any) => {
        this.getLanguages(response.data.data);
      }).catch((err: any) => {
        console.log(err)
      })
  }

  private getLanguages(data: any) {
      this.setUserLanguage(data.attributes.languages[1])
  }

  getApps () {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  private getAccountConcept(data: any, roles: any, subscription: any) {
    ConceptApi.get(this.activeConcept.id).then(response => {
      console.log(response.data.data)
      this.setConceptSettings(response.data.data)
      this.setSubscription(subscription)
      this.getApps()
      // this.$refs.welcomeModal.notify = true
      // this.$refs.welcomeModal.success = true
      this.$router.push({name: 'dashboard'})

    })
  }

  private getSubscriptionFn(data: any, roles: any) {
      this.setUserConcept(data.attributes.concepts[0])
      this.setUser(data)
      this.setUserRole(roles)
      Subscription.getSubscription().then(response => {
        this.getAccountConcept(data,roles, response.data.data)
      }).catch(err => {
        this.getAccountConcept(data,roles, null)
        // this.$refs.welcomeModal.notify = true
        // this.$refs.welcomeModal.success = true
      })
    }

  signInWithFoodics() {
    // window.location.href = 'https://app.getsolo.io/register/foodics';
    FoodicsAuth.redirect();
  }
}
