
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Upsell } from "@/services/SOLO";
import { Select, Option, Autocomplete } from "element-ui";
import { translations } from '@/mixins'

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete,
  },
  mixins: [translations],
})
export default class MenuUpsells extends Vue {
    upsells: Array<any> = [];
    items: Array<any> = [];
    item: string = '';
    itemSelected: any = null;
    menuId: string = '';
    created() {
        this.menuId = this.$route.params.id;
        // this.loadItems();
        this.loadUpsells();
    }

    handleSelect(e: any) {
        this.itemSelected = e;
    }

    onFetch(queryString: string, cb: Function) {
        Upsell.loadItems(this.menuId, queryString)
            .then((response) => {
                console.log('response loadItems: ', response);
                const data = [...response.data.data];
                console.log('mapped: ', data.map((d: any) => {
                    return {
                        value: d.attributes.name, 
                        id: d.id,
                    };
                }));
                const mapped = data.map((d: any) => {
                    return {
                        value: d.attributes.name, 
                        id: d.id,
                    };
                });
                cb(mapped);
            });
    }

    loadItems() {
        
    }

    loadUpsells() {
        Upsell.loadUpsells(this.menuId)
            .then((response) => {
                console.log('response loadUpsells: ', response);
                const included = response.data.included;
                this.upsells = [...response.data.data];
                this.upsells = this.upsells.map((u: any) => {
                    return {
                        id: u.id,
                        item: included.find((i: any) => i.id === u.attributes['item-id'].toString())
                    };
                });
                console.log('this.upsells: ', this.upsells);
            });
    }

    onAddUpsell() {
        Upsell.addUpsell(this.menuId, this.itemSelected.id).then(() => this.loadUpsells());
    }

    editUpsell() {
        // edit logic here
    }

    removeUpsell(id: string, idx: number) {
        var r = confirm("Are you sure you want to delete this upsell?");
        if (r) {
            Upsell.removeUpsell(this.menuId, id).then(() => {
                this.upsells.splice(idx, 1);
                // Should enable later I found a bug after deleting
                // this.loadUpsells();
            });
        }
    }
}
