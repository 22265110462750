
// npm packages
import {Component, Vue} from 'vue-property-decorator';
import {DatePicker, Form, FormItem, Input, Option, Select, TimeSelect, Upload, Button} from 'element-ui';
import {mapGetters} from 'vuex';
import timezone from 'moment-timezone';
// our packages
import {Concept as ConceptService, Messaging, Promotion, Item, Category, Order} from '@/services/SOLO';
import TabHeader from '@/views/Admin/pages/Messaging/components/TabHeader';
import {translations} from '@/mixins'

interface DataObject {
  selected_segment: string;
  // title_ar_sa: string;
  // title_en_us: string;
  // message_ar_sa: string;
  // message_en_us: string;
  title: any;
  message: any;
  is_pushed: boolean;
  schedule_date: any;
  schedule_time: any;
  deep_link: any
  image: any
}

interface SimpleItem {
  value: string;
  text: string;
}

@Component({
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      // count: 'messaging/getDeviceCount',
      segments: 'messaging/getSegments',
      conceptSettings: 'account/getConceptSettings',
      activeMenu: 'menu/getMenus',
      getLocale: "app/getLocale",
    }),
  },
  components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Form.name]: Form,
    [Button.name]: Button,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
    [Upload.name]: Upload,
    TabHeader,
  },
  filters: {
    formTitle(str: any) {
      return str.split('-').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    fieldTitle(str: any) {
      return str.split('-').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    capitalizeFirstLetter(str: any) {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mixins: [translations],
})
export default class TabCompose extends Vue {
  $notify: any
  // count!: number;
  segments!: Array<any>;
  data: DataObject = {
    selected_segment: '',
    // title_ar_sa: '',
    // title_en_us: '',
    // message_ar_sa: '',
    // message_en_us: '',
    title: {},
    message: {},
    is_pushed: false,
    schedule_date: '',
    schedule_time: '',
    deep_link: '',
    image: ''
  };
  saving: Boolean = false
  conceptSettings!: any;
  activeConcept!: any;
  activeMenu!: any;
  public translate!: Function
  options: SimpleItem[] = [
    {value: '', text: 'All Customers'},
  ];
  selectedSegment: string = '';
  // currentConcept: string = '';
  link: any = ''
  target_link: any = ''
  target_link_item: any = ''
  image: any = ''
  link_url: any = ''
  image_url: any = ''
  menus: any = []
  link_options = [
    {key: 'Home', value: 'home'},
    {key: 'Category', value: 'category'},
    {key: 'Item', value: 'item'},
    {key: 'Digital Coupon', value: 'digital-coupon'},
    {key: 'Cart', value: 'shopping-cart'},
  ]
  target_menu: any = ''
  is_menu_loaded: boolean = false
  target_link_options: any = []
  event_select: any = ''
  link_selected: any = {}
  getLanguages!: Function
  arrayLang: Array<string> = []

  mounted() {
    this.getMenus()

    this.arrayLang = this.getLanguages()

    this.arrayLang.forEach((lang: string) => {
      this.data.title[lang] = ''
      this.data.message[lang] = ''
    })
  }

  created() {
    // this.getCurrentConcept();
  }

  // async getCurrentConcept() {
  //   await ConceptService.default().then((response: any) => {
  //     this.currentConcept = response.data.data.attributes.label;
  //   })
  // }

  onSendPushNotification() {
    // const tz = timezone.tz.guess();
    this.saving = true;
    // const r = confirm(`Are you sure you want to send to ${this.count} customer${this.count > 1 ? 's' : ''} on the ${this.currentConcept} app?`);

    const r = confirm(`Are you sure?`);
    let scheduled_date = null;
    if (r) {
      const defaultTimezone = this.conceptSettings.attributes['default-timezone'] || 'GMT+3';
      const tz = `Etc/${defaultTimezone}`;
      let formData = new FormData();
      formData.append('segment_id', this.data.selected_segment);
      // formData.append('title[ar-sa]', this.data.title_ar_sa);
      // formData.append('title[en-us]', this.data.title_en_us);
      // formData.append('message[ar-sa]', this.data.message_ar_sa);
      // formData.append('message[en-us]', this.data.message_en_us);
      this.arrayLang.forEach((lang: string) => {
        formData.append(`title[${lang}]`, this.data.title[lang]);
        formData.append(`message[${lang}]`, this.data.message[lang]);
      });
      Object.keys(this.link_selected).forEach(key => formData.append(`deep-link[${key}]`, this.link_selected[key]));
      
      if (this.data.is_pushed) {
        scheduled_date = this.data.schedule_date.toString().replace('00:00:00', `${this.data.schedule_time}:00`);
        scheduled_date = timezone.utc(scheduled_date).tz(tz).format('YYYY-MM-DD HH:mm:ss');
      } else {
        scheduled_date = ''
        // scheduled_date = timezone.utc().tz(tz).format('YYYY-MM-DD HH:mm:ss');
      }
      console.log(this.data.deep_link)
      console.log(this.data.image)
      formData.append('scheduled_date', scheduled_date);
      formData.append('image-uri', this.image_url);

      Messaging.sendPushNotification(formData)
          .then(() => {
            /* @ts-ignore */
            analytics.track('send_push_notifications', {
              is_scheduled_notification: this.data.is_pushed
            });
            alert('Notification was sent successfully.');
            // location.reload()
            this.saving = false;
            this.data.selected_segment = '';
            // this.data.title_ar_sa = '';
            // this.data.message_ar_sa = '';
            // this.data.title_en_us = '';
            // this.data.message_en_us = '';
            this.data.title = {};
            this.data.message = {};
            this.data.schedule_date = '';
            this.data.schedule_time = '';
            this.data.is_pushed = false;
            this.link_selected = {};

            this.link = '';
            this.target_menu = '';
            this.target_link = '';
          })
          .catch((error) => {
            console.log('error: ', error)
            this.saving = false;
            this.$notify({
              title: "INVALID",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: error.response.data.error.detail,
              type: "danger",
              icon: "fas fa-bomb",
            })
          });
    } else {
      this.saving = false;
    }
  }

  processLink(event: any) {
    this.target_menu = ''
    this.target_link = ''
    this.target_link_options = []
    this.target_link_item = ''
    this.event_select = event
    this.link_selected = {
      'type': event
    }

    this.link_url = this.event_select == 'home' || this.event_select == 'shopping-cart' ?
        `https://${this.activeConcept.key}.page.link/${this.event_select}` :
        ''
    this.data.deep_link = this.link_url
    if (this.event_select === 'digital-coupon') {
      this.getLinkResource(this.event_select, this.target_menu)
    }
  }

  selectedMenu(event: any) {
    this.target_menu = event
    this.is_menu_loaded = true
    this.link_selected = {...this.link_selected, 'menus': event}
    this.getLinkResource(this.event_select, this.target_menu)
  }

  getLinkResource(data: any, menu_id: any) {
    switch (data) {
      case 'item':
        Messaging.menuItem(menu_id).then((response) => {
          this.target_link_options = response.data.data
        })
        break
      case 'category':
        Category.all(menu_id).then((response) => {
          this.target_link_options = response.data.data
        })
        break
      case 'digital-coupon':
        Messaging.getDigitalCoupons().then((response) => {
          this.target_link_options = response.data.data.filter((a: any) => {
            return a.attributes.status === 'active'
          })
          console.log(this.target_link_options)
        })
        break
    }
  }

  processLinkChild(event: any) {
    this.target_link_item = event
    this.link_url = `https://${this.activeConcept.key}.page.link/${this.event_select}-${this.target_link_item}`
    this.data.deep_link = this.link_url
    this.link_selected = {...this.link_selected, 'value': event}
    console.log("process link", this.link_url);
  }

  async handleChange(file: any) {

    let imgForm = new FormData()
    imgForm.append('pn_image', file.raw)
    this.image = file.name

    await Messaging.uploadToS3(imgForm).then((response: any) => {
      // this.uploadedFileName = file.name
      this.image_url = response.data
      console.log('check img response', this.image_url);
      this.data.image = this.image_url
      console.log(this.image_url)
    }).catch((err: any) => {
      this.$notify({
        title: "INVALID",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: err.response.data.error.detail,
        type: "danger",
        icon: "fas fa-bomb",
      })
    })
  }

  getMenus() {
    Messaging.menus().then((response: any) => {
      this.menus = response.data.data;
    });
  }
}
